import { getImage, StaticImage } from 'gatsby-plugin-image'

import CompanyWhyHireBlock from '~/components/blocks/CompanyWhyHireBlock'
import FaqSection from '~/components/blocks/FAQ'
import HeadSection from '~/components/blocks/HeadSection'
import Testimonial from '~/components/blocks/TestimonialPost'
import CallToAction from '~/components/call-to-actions/CallToAction'
import ServicesIconSmallCard from '~/components/cards/ServicesIconSmallCard'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import ClientVideoQuotes from '~/components/items/ClientVideoQuotes'
import DiscoverWorksItem from '~/components/items/DiscoverWorksItem'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSectionServices from '~/components/sections/PrimeSectionServices'
import SuccessStoriesSliderSection from '~/components/sections/SuccessStoriesSliderSection'
import SubtractBlock from '~/components/shared/SubtractBlock'
import StickyNavigation from '~/components/sticky/StickyNavigation'
import Anchors from '~/mock/services/pwa/Anchors'
import PWABenefits from '~/mock/services/pwa/PWABenefits'
import PWACompanies from '~/mock/services/pwa/PWACompanies'
import PWAFAQ from '~/mock/services/pwa/PWAFAQ'
import PWAIndustries from '~/mock/services/pwa/PWAIndustries'
import PWAProcess from '~/mock/services/pwa/PWAProcess'
import PWAWhyChoose from '~/mock/services/pwa/PWAWhyChoose'
import { fileToImageLikeData } from '~/utils'
import AwardsServicesSection from '~/views/Services/components/AwardsServicesSection'
import ServicesCompaniesSection from '~/views/Services/components/ServicesCompaniesSection'
import ServicesSectionBeforeTestimonials from '~/views/Services/components/ServicesSectionBeforeTestimonials'
import ServicesTechStackSection from '~/views/Services/components/ServicesTechStackSection'
import usePwaStaticQuery from '~/views/Services/Pwa/usePwaStaticQuery'

import * as containerStyles from './Pwa.module.scss'

const clientName = 'Geert-Jan Waasdorp'

const PwaDevelopment = () => {
  const query = usePwaStaticQuery()

  const tailoredOne = getImage(fileToImageLikeData(query.tailoredOne))
  const tailoredTwo = getImage(fileToImageLikeData(query.tailoredTwo))
  const tailoredThree = getImage(fileToImageLikeData(query.tailoredThree))
  const tailoredFour = getImage(fileToImageLikeData(query.tailoredFour))
  const portfolioWowner = getImage(fileToImageLikeData(query.portfolioWowner))
  const clientImpact = getImage(fileToImageLikeData(query.clientImpact))
  const quoteCodicaEugenePhoto = getImage(
    fileToImageLikeData(query.quoteCodicaEugenePhoto),
  )

  return (
    <MainLayout scrollTop>
      <PrimeSectionServices
        titleTop="Progressive Web Apps"
        title="Development Services"
        description="Looking to extend your online presence? Make your brand stand out with a cross-platform PWA."
        breadcrumbSecondLevel="Services"
        breadcrumbSecondLevelPath="/services/"
        breadcrumbCurrent="Progressive Web Apps Development Services"
        breadcrumbCurrentPath="/services/progressive-web-apps-development/"
      />

      <StickyNavigation anchors={Anchors} />

      <section
        className={containerStyles.pwaBenefitWebsiteSection}
        id="services"
      >
        <HeadSection
          position="center"
          title="How a PWA will benefit your website"
          description="With progressive web app development services, you will get a fast and modern cost-effective alternative to native apps. Turn your website into a progressive web app and quickly boost your business."
          titleLarge
          descriptionLarge
        />
        <ServicesIconSmallCard isBorder dataContent={PWAProcess} />
      </section>

      <section className={containerStyles.pwaCycleDevelopmentSection}>
        <HeadSection
          position="center"
          title="Cost-effective mobile presence with a PWA"
          description="Progressive web application development is a great option for businesses that are looking to quickly enhance their mobile presence and grow website traffic within a reasonable budget."
          descriptionLarge
        />
        <ServicesSectionBeforeTestimonials
          imageOne={tailoredOne}
          imageTwo={tailoredTwo}
          content={[
            {
              text: 'Mobile-generated traffic is growing at tremendous speed. Currently, mobile phones produce 60% of all the traffic in the world. At the same time, Google states that 53% of users will abandon a site if it takes longer than 2 seconds to load, which means that having a fast-loading website should be a priority.',
            },
            {
              text: 'How can you improve your website loading speed and effectively engage with the mobile audience at the same time? The PWA (progressive web app) technology is the best and most cost-effective solution. By making your website a progressive web app, you will make it load faster than regular websites.',
            },
            {
              text: 'Additionally, while still being a web page, your PWA will provide visitors with a great user experience close to that of a native app, boost traffic and increase conversion rate. Therefore, building your PWA with the help of an experienced progressive web app development company will benefit your business in many ways.',
            },
          ]}
        />

        <div
          className={containerStyles.pwaCycleDevelopmentSection__testimonial}
        >
          <Testimonial
            image={quoteCodicaEugenePhoto}
            name="Eugene"
            position="Tech Lead & React expert at Codica"
            quote={[
              {
                text: '“By creating a progressive web app, you will get a cross-platform application for a cost much lower than that of building separate native iOS and Android apps. And as a bonus - your PWA website will rank higher in Google search results.',
              },
              {
                text: 'Being a PWA developer engaged in multiple progressive app development projects, I can also add that PWAs are secure and fast-loading. This technology is a great alternative to native apps, and a knowledgeable progressive web app development company will help you make the most of PWAs.”',
              },
            ]}
          />
        </div>

        <CallToAction
          title="Planning to build a progressive web app?"
          subtitle="We would love to help you!"
          classNameForGA="servicesCTA_1"
          linkName="Talk to an expert"
          link="/contacts/"
        />
      </section>

      <section className={containerStyles.pwaCardRowIconsSection} id="benefits">
        <HeadSection
          position="center"
          title="Benefits of Progressive Web Apps"
          description="By combining the best of the web and mobile, the PWA technology offers a great number of advantages. While still being a web page, your PWA website will be able to compete with native apps in many aspects, at the same time being cross-platform and more secure. What’s more, when collaborating with an experienced progressive web app agency, you get the cost of PWA development that is considerably lower than native app development."
          descriptionLarge
        />
        <ServicesIconSmallCard isBorder dataContent={PWABenefits} />
        <SubtractBlock />
      </section>

      <section
        className={containerStyles.pwaServicesCompaniesSection}
        id="companies"
      >
        <HeadSection
          position="center"
          title="Companies that have successfully implemented the PWA technology"
          description="Since PWAs were introduced by Google in 2015, numerous businesses have benefited from creating a progressive web app. We will name a few of them."
          titleLarge
          descriptionLarge
        />
        <ServicesCompaniesSection dataContent={PWACompanies} />
      </section>

      <section
        className={containerStyles.pwaServicesCycleDevelopmentSection}
        id="approach"
      >
        <HeadSection
          position="center"
          title="Our approach to progressive web application development"
          description="At Codica, we have been providing PWA development services for our customers since 2015, using the best practices recommended by Google."
        />
        <ServicesSectionBeforeTestimonials
          imageOne={tailoredThree}
          imageTwo={tailoredFour}
          content={[
            {
              text: 'Being a PWA development company, we are passionate about this technology and believe that it is here to stay and change the history of mobile apps. This is why all the web apps created by our team are PWA (progressive web apps) by default.',
            },
            {
              text: 'With the help of service workers, we create PWA websites that load fast, keep your visitors engaged, and improve your brand visibility via cross-platform presence.',
            },
            {
              text: 'In progressive web development, our PWA developers keep end users in mind and consider particular business needs and industry peculiarities. Thus, we ensure that PWA projects delivered bring the best results and help your business grow.',
            },
          ]}
        />
      </section>

      <CallToAction
        title="Ready to build your progressive web app?"
        subtitle="Request a free quote"
        classNameForGA="servicesCTA_2"
        linkName="Contact us"
        link="/contacts/"
      />

      <section
        className={containerStyles.pwaDomainExpertiseSection}
        id="industries"
      >
        <HeadSection
          position="center"
          title="Domain expertise you can rely on"
          description="Codica team has been providing progressive web app development services for many years. Building PWA websites for particular industries has allowed us to grow in-house expertise which we share with our customers. We provide consultations on every stage of progressive web development and build products following the best industry standards."
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={PWAIndustries} />
      </section>

      <section className={containerStyles.pwaFeaturedCaseSection}>
        <HeadSection position="center" title="Featured PWA project" />
        <DiscoverWorksItem
          gradient="gradientWowner"
          image={portfolioWowner}
          title="Wowner is an online job search solution for the Netherlands market. It helps people to find new relevant jobs based on their preferences and skills."
          resultsLeft="Built a cross-platform PWA to save costs and time for mobile apps development."
          resultsRight="Developed a ready-to-the-market job search platform within a short time frame."
          link="intelligent-job-matching-service"
          review="“Codica’s ability to start fast, professional approach to custom software development, clear communication, result-oriented team, and the final product delivered - all these proved that we made the right choice. We hope for new mutual projects in the future!”"
          clientPhoto={
            <StaticImage
              src="../../../assets/images/avatars/clients/client-wowner.png"
              alt={`${clientName} | Codica`}
              title={clientName}
              width={50}
              height={50}
            />
          }
          clientName={clientName}
          clientPosition="Co-Owner of Wowner"
        />
      </section>

      <section className={containerStyles.pwaTechStackSection} id="tech-stack">
        <HeadSection
          position="center"
          title="Perfect tech stack for PWA websites"
          description="You can rely on Codica as an experienced progressive web app agency. Over the years, we have picked a list of PWA development tools helping us build high-quality engaging apps that help businesses grow. Together with our efficient development process, these tools enable us to keep the cost of PWA development services within our clients’ expectations."
          titleLarge
          descriptionLarge
        />
        <ServicesTechStackSection />
      </section>

      <section
        className={containerStyles.pwaSuccessStoriesSection}
        id="testimonials"
      >
        <HeadSection position="center" title="Success stories" />
        <SuccessStoriesSliderSection btnName="View all our works" />
      </section>

      <section className={containerStyles.pwaWhyChooseSection}>
        <HeadSection
          position="center"
          title="Why you should choose Codica as PWA development company"
        />
        <CompanyWhyHireBlock dataContent={PWAWhyChoose} />
      </section>

      <section className={containerStyles.pwaServiceAchievementsSection}>
        <HeadSection
          position="center"
          title="Our achievements"
          description="We are immensely thrilled to develop awesome projects. Independent ratings and research firms recognize our work."
          descriptionLarge
        />
        <AwardsServicesSection />
      </section>

      <section className={containerStyles.pwaServiceClientsSection}>
        <HeadSection
          position="center"
          title="Our clients say"
          description="Genuine feedback from our clients is precious to us and inspires further progress. We invite you to explore the transformative business experiences of people who entrusted their projects to Codica."
          descriptionLarge
        />
        <div className="container container-md">
          <ClientVideoQuotes
            name="Richard Gunter"
            location="Israel"
            position="Founder of Impact Personal Training"
            image={clientImpact}
            quote={[
              {
                text: '“Coming from a gym environment, I had no idea how to build, create, and develop an app. Everything I needed to do was explained to me in layman’s terms. I was always up to date with where they were at on the project itself, which I found really helpful.”',
              },
            ]}
            link="fitness-progressive-web-application"
            videoLink="https://www.youtube.com/embed/r1tbx4MEmrY"
          />
        </div>
      </section>

      <section id="free-quote">
        <PrimeContactFormSection
          title="Let’s build a great progressive web app together!"
          widthTitle="700px"
        />
      </section>

      <section id="faq">
        <FaqSection title="FAQ" dataContent={PWAFAQ} />
      </section>
    </MainLayout>
  )
}

export default PwaDevelopment
