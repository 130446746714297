import icon1 from '~/assets/images/services/icons/aliexpress.svg?file'
import icon3 from '~/assets/images/services/icons/flipkart.svg'
import icon4 from '~/assets/images/services/icons/george.svg'
import icon6 from '~/assets/images/services/icons/housing.svg'
import icon2 from '~/assets/images/services/icons/the-washington-post.svg'
import icon5 from '~/assets/images/services/icons/twitter.svg?file'
import { ServicesCompany } from '~/views/Services/components/ServicesCompaniesSection/ServicesCompaniesSection.view'

const PWACompanies: ServicesCompany[] = [
  {
    icon: icon1,
    head: 'AliExpress',
    item: '74% increase in time spent per session across all browsers',
    item2:
      '104% for new users across all browsers; 82% increase in iOS conversion rate',
  },
  {
    icon: icon2,
    head: 'The Washington Post',
    item: '23% increase in mobile search users who return within 7 days',
    item2:
      '88% improvement in load time for AMP content versus traditional mobile web',
  },
  {
    icon: icon3,
    head: 'Flipkart',
    item: '3x more time spent on site',
    item2: '40% higher re-engagement rate',
    item3:
      '70% greater conversion rate among those arriving via Add to Homescreen',
  },
  {
    icon: icon4,
    head: 'George.com',
    item: '31% - Increase in conversion rate',
    item2: '20% - More page views per visit',
    item3: '2x - Lower bounce rate',
    item4: '3.8x - Faster average page load time',
  },
  {
    icon: icon5,
    head: 'Twitter',
    item: '75% increase in tweets sent',
    item2: '20% decrease in bounce rate',
    item3: '65% increase in pages per session',
  },
  {
    icon: icon6,
    head: 'Housing.com',
    item: '38% more conversions',
    item2: '40% lower bounce rate',
    item3: '10% longer average session',
    item4: '30% faster page load',
  },
]
export default PWACompanies
