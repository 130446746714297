import { FAQ } from '~/types/data-array'

const PWAFAQ: FAQ[] = [
  {
    question: 'How long does it take to develop a progressive web app?',
    answer:
      'The time it takes to develop a progressive web app varies, placed at a minimum of around 2 to 6 months.',
  },
  {
    question: 'What is the cost range for PWA development services?',
    answer:
      'The price range of a PWA development stretches between $20,000 for basic projects and $50,000 for those of a complex level of sophistication.',
  },
  {
    question:
      'What factors influence the timeframe and cost of PWA development?',
    answer:
      'The development time and cost of a PWA directly depend on the complexity of the project, functionality involved, design, UX, technologies in use, and further maintenance with continuous support.',
  },
  {
    question:
      'How does PWA development compare to native app development in terms of cost?',
    answer:
      'Compared to the native development of applications, the development of PWAs is usually economical in expense because it builds on web technologies with a single code base for cross-platform compatibility against development for iOS and Android separately.',
  },
  {
    question: 'What kind of ongoing support is provided for a PWA post-launch?',
    answer:
      'The kinds of continued support rendered for a PWA are maintenance, security updates, performance optimization, and feature enhancements guided by user feedback and market dynamics.',
  },
]

export default PWAFAQ
