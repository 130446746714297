import { BaseMock } from '~/types/mock'

const PWAWhyChoose: BaseMock[] = [
  {
    title: 'Save costs and launch quickly',
    description:
      'Our progressive web application development process is efficient and fully transparent, allowing us to deliver your solution on time and on budget.',
  },
  {
    title: 'Get expert recommendations',
    description:
      'Having delivered numerous PWA websites, our experienced team has a profound market and industry knowledge.',
  },
  {
    title: 'The right solution for your needs',
    description:
      'Clients’ success is our priority. We carefully align your business requirements with the functionality of PWA apps to ensure the best value.',
  },
  {
    title: 'Best industry standards',
    description:
      'We follow all industry trends and Google recommendations and adapt our progressive app development process according to the best standards.',
  },
  {
    title: 'Support after launch',
    description:
      'Our PWA development services don’t stop when your solution goes live. Codica’s reliable support team will ensure your website runs smoothly.',
  },
  {
    title: 'Enthusiastic team that cares',
    description:
      'Being small and flexible, our team cares about each of our PWA projects, and will work as hard for the best results as your in-house employees.',
  },
]

export default PWAWhyChoose
