// extracted by mini-css-extract-plugin
export var pwaBenefitWebsiteSection = "v_cJ";
export var pwaCardRowIconsSection = "v_cM";
export var pwaCycleDevelopmentSection = "v_cK";
export var pwaCycleDevelopmentSection__testimonial = "v_cL";
export var pwaDomainExpertiseSection = "v_cQ";
export var pwaFeaturedCaseSection = "v_cR";
export var pwaServiceAchievementsSection = "v_cW";
export var pwaServiceClientsSection = "v_cX";
export var pwaServicesCompaniesSection = "v_cN";
export var pwaServicesCycleDevelopmentSection = "v_cP";
export var pwaSuccessStoriesSection = "v_cT";
export var pwaTechStackSection = "v_cS";
export var pwaWhyChooseSection = "v_cV";