import { ExpertiseSectionMock } from '~/types/mock'

const PWABenefits: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_35',
    title: 'Fast-loading',
  },
  {
    icon: 'spriteIcon_36',
    title: 'Light',
  },
  {
    icon: 'spriteIcon_37',
    title: 'Secure',
  },
  {
    icon: 'spriteIcon_38',
    title: 'Engaging',
  },
  {
    icon: 'spriteIcon_32',
    title: 'Seo-friendly',
  },
  {
    icon: 'spriteIcon_39',
    title: 'Working offline',
  },
  {
    icon: 'spriteIcon_40',
    title: 'Easy to install',
  },
  {
    icon: 'spriteIcon_34',
    title: 'Cost-effective',
  },
  {
    icon: 'spriteIcon_25',
    title: 'Cross-platform',
  },
]

export default PWABenefits
