import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

const usePwaStaticQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      tailoredOne: file(
        relativePath: { eq: "company-photo/company-photo-41.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      tailoredTwo: file(
        relativePath: { eq: "company-photo/company-photo-42.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      tailoredThree: file(
        relativePath: { eq: "company-photo/company-photo-43.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      tailoredFour: file(
        relativePath: { eq: "company-photo/company-photo-44.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      portfolioWowner: file(
        relativePath: { eq: "homepage/discover-works-portfolio-1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      clientImpact: file(
        relativePath: { eq: "avatars/clients/large-photo/client-impact.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      quoteCodicaEugenePhoto: file(
        relativePath: { eq: "avatars/FE-Eugene.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  return query
}

export default usePwaStaticQuery
