import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

import { PhotosQuery } from '~/types/graphql'
import { DataContentProps } from '~/types/helper'
import { cx, fileToImageLikeData } from '~/utils'

import * as containerStyles from './ServicesCompaniesSection.module.scss'

export interface ServicesCompany {
  icon: string
  head: string
  item: string
  item2: string
  item3?: string
  item4?: string
}

const ServicesCompaniesSection = ({
  dataContent,
}: DataContentProps<ServicesCompany>) => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      pwaApproach: file(relativePath: { eq: "services/pwa-approach.jpg" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  const pwaApproach = getImage(fileToImageLikeData(query.pwaApproach))

  return (
    <div className="container container-md">
      <div className="row">
        <div className="col-12">
          <div className={containerStyles.servicesCompaniesSection__largeImage}>
            <GatsbyImage
              image={pwaApproach as IGatsbyImageData}
              alt="Companies that have successfully implemented the PWA technology"
              title="Firms that have deployed the PWA technology successfully"
              loading="lazy"
            />
          </div>
        </div>
        {dataContent.map(({ icon, head, item, item2, item3, item4 }) => (
          <div
            key={head}
            className={cx(
              'col-12 col-md-6 col-lg-4',
              containerStyles.servicesCompaniesSection__col,
            )}
          >
            <div className={containerStyles.servicesCompaniesSection__card}>
              <div className="d-flex align-items-center">
                <div className={containerStyles.servicesCompaniesSection__icon}>
                  <img
                    src={icon}
                    alt={`${head} | Codica`}
                    title={head}
                    loading="lazy"
                  />
                </div>
                <div className={containerStyles.servicesCompaniesSection__head}>
                  {head}
                </div>
              </div>
              <ul className={containerStyles.servicesCompaniesSection__list}>
                <li
                  className={containerStyles.servicesCompaniesSection__listItem}
                >
                  {item}
                </li>
                <li
                  className={containerStyles.servicesCompaniesSection__listItem}
                >
                  {item2}
                </li>
                {item3 && (
                  <li
                    className={
                      containerStyles.servicesCompaniesSection__listItem
                    }
                  >
                    {item3}
                  </li>
                )}
                {item4 && (
                  <li
                    className={
                      containerStyles.servicesCompaniesSection__listItem
                    }
                  >
                    {item4}
                  </li>
                )}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ServicesCompaniesSection
