import { ExpertiseSectionMock } from '~/types/mock'

const PWAProcess: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_32',
    title: 'Rank higher in Google search',
    description:
      'Mobile-first approach guarantees that your website will be listed higher in Google search results, be it on mobile or desktop.',
  },
  {
    icon: 'spriteIcon_33',
    title: 'Improve user acquisition',
    description:
      'Your website visitors will quickly turn into customers as they can easily install your PWA directly from the website.',
  },
  {
    icon: 'spriteIcon_34',
    title: 'Increase conversion rate',
    description:
      'Fast loading time (less than 1 second) and push notifications increase user engagement rate and boost conversion.',
  },
]

export default PWAProcess
